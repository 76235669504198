



import { Component, Vue } from 'vue-property-decorator'
import IssueListDraggable from '@/main/issues/components/IssueListDraggable.vue'

@Component({
  components: { IssueListDraggable }
})
export default class IssueListView extends Vue {}
