













































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Sprint } from '@/models/Sprint.model'
import store from '@/store'
import axios, { AxiosResponse } from 'axios'
import { Issue } from '@/models/Issue.model'

@Component
export default class StartSprintModal extends Vue {
  @Prop() private value!: boolean
  @Prop() private issues!: Issue[]
  private isLoading = false
  private startEndDates = []

  private formData = {
    name: '',
    goal: ''
  }

  async startSprint() {
    const currentProduct = store.state.product.currentProduct
    if (!currentProduct) {
      throw new Error('No current product')
    }

    try {
      this.isLoading = true

      const payload = {
        product: currentProduct.iri(),
        issues: this.issues.map((issue: Issue) => issue.iri()),
        startAt: this.startEndDates[0],
        endAt: this.startEndDates[1],
        ...this.formData
      }

      const response = await axios.post<AxiosResponse>('/sprints', payload)
      const sprint = await Sprint.createFromResponse(response)
      this.$emit('started', sprint)
      this.closeModal()
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  cancel() {
    this.$emit('canceled', true)
    this.closeModal()
  }

  closeModal() {
    this.$emit('input', false)
  }
  onlyWeekdays(date: string) {
    const dateObj = new Date(date)
    return !(dateObj.getDay() == 6 || dateObj.getDay() == 0)
  }
  displayDate(dates: string[]) {
    if (dates.length === 0) {
      return this.$i18n.t('sprint.fields.timeframe')
    }
    if (dates.length === 1) {
      return (this as any).$dateTime.formatDate(dates[0], this.$i18n.locale, 'll')
    }
    if (dates.length === 2) {
      const startDate = (this as any).$dateTime.formatDate(dates[0], this.$i18n.locale, 'll')
      const endDate = (this as any).$dateTime.formatDate(dates[1], this.$i18n.locale, 'll')
      return `${startDate} - ${endDate}`
    }
  }
}
