






























import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { IssueService } from '@/main/issues/services/IssueService'

@Component
export default class IssueFormQuickCreate extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  isLoading = false
  confirmCancel = false
  formData = {
    title: '',
    description: ''
  }

  resetForm() {
    this.formData = {
      title: '',
      description: ''
    }

    this.$nextTick(() => {
      this.$refs.form.reset()
    })
  }

  async save() {
    try {
      this.isLoading = true
      const createdIssue = await IssueService.createIssue(this.formData)
      this.$emit('issueCreated', createdIssue)
      this.resetForm()
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }
  cancel(force: boolean) {
    if (!force && this.$refs.form.flags.dirty) {
      this.confirmCancel = true
      return
    }
    this.resetForm()
    this.$emit('canceled')
  }
}
