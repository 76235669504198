

























































































































































































import draggable from 'vuedraggable'
import axios, { AxiosResponse } from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import SkeletonListLoader from '@/components/shared/skeleton/ListLoader.vue'
import IssueListItem from './IssueListItem.vue'
import { Issue } from '@/models/Issue.model'
import { LexoRankService } from '@/services/lexorank/LexoRankService'
import { IssueService } from '@/main/issues/services/IssueService'
import NoDataAlert from '@/components/shared/alert/NoDataAlert.vue'
import router from '@/router'
import { Sprint } from '@/models/Sprint.model'
import StartSprintModal from '@/main/sprint/component/StartSprintModal.vue'
import { Product } from '@/models/Product.model'
import IssueFormQuickCreate from '@/main/issues/components/IssueFormQuickCreate.vue'
import store from '@/store'

@Component({
  components: {
    IssueFormQuickCreate,
    StartSprintModal,
    NoDataAlert,
    SkeletonListLoader,
    IssueListItem,
    draggable
  }
})
export default class IssueListDraggable extends Vue {
  private issues: Issue[] = []
  private sprintIssues: Issue[] = []
  private page = 1
  private initialLoadingSprintDone = false
  private initialLoadingIssuesDone = false
  private showCreateDialog = false

  private isLoading = false
  private startEndDates = []

  private formData = {
    name: '',
    goal: ''
  }

  async created() {
    await this.loadChunkIssues(true)
    await this.loadNextSprint()
  }

  onIssueCreated() {
    this.reload()
    this.closeDialog()
  }

  closeDialog() {
    this.showCreateDialog = false
  }

  reload() {
    this.issues = []
    this.sprintIssues = []
    this.page = 1
    this.loadChunkIssues()
    this.loadNextSprint()
  }

  async loadChunkIssues(firstLoad = false) {
    const mappedIssues = await IssueService.getIssuesForCurrentProduct(this.page)
    if (firstLoad) this.initialLoadingIssuesDone = true
    if (mappedIssues.length) {
      this.page += 1
      this.issues.push(...mappedIssues)
      return true
    }
    return false
  }

  async loadNextSprint() {
    const mappedIssues = await IssueService.getIssuesForNextSprint()
    this.initialLoadingSprintDone = true

    if (mappedIssues.length) {
      this.sprintIssues = mappedIssues
      return true
    }
    return false
  }

  async infiniteHandler($state: any) {
    const loaded = await this.loadChunkIssues()
    loaded ? $state.loaded() : $state.complete()
  }

  get dragOptions() {
    if (this.$vuetify.breakpoint.mobile) {
      return {
        animation: 200,
        handle: '.handle'
      }
    }
    return {
      animation: 200
    }
  }

  get showSprintBlock() {
    return this.sprintIssues.length > 0 || this.issues.length > 0
  }

  get currentProduct(): Product {
    return this.$store.state.product.currentProduct
  }

  async addedToSprint(customEvent: any) {
    const issue = this.sprintIssues[customEvent.newIndex]
    await axios.put<AxiosResponse>(issue.iri(), {
      prepareForNewSprint: true
    })

    this.updateLexoRankSprint(customEvent)
  }

  async removeFromSprint(customEvent: any) {
    const issue = this.issues[customEvent.newIndex]
    await axios.put<AxiosResponse>(issue.iri(), {
      prepareForNewSprint: false
    })
  }

  sprintStarted(sprint: Sprint) {
    router.push({ path: sprint.iri() })
  }

  updateLexoRankBacklog(event: any) {
    let rankAbove = null
    let rankBelow = null
    // dropped at top of backlog
    if (event.newIndex === 0) {
      // check for last issue rank in sprint, if present
      if (this.sprintIssues.length > 0) {
        rankAbove = this.sprintIssues[this.sprintIssues.length - 1].lexoRank()
      }
    } else {
      // if not at top => just take the one above
      rankAbove = this.issues[event.newIndex - 1].lexoRank()
    }
    // get rank of following issue, if present
    if (event.newIndex + 1 < this.issues.length - 1) {
      rankBelow = this.issues[event.newIndex + 1].lexoRank()
    }

    this.issues[event.newIndex].rank = LexoRankService.calculateRank(rankAbove, rankBelow).toString()
    IssueService.updateRank([this.issues[event.newIndex]])
  }

  updateLexoRankSprint(event: any) {
    let rankAbove = null
    let rankBelow = null
    // dropped at bottom of sprint
    if (event.newIndex === this.sprintIssues.length - 1) {
      // check for last issue rank in sprint, if present
      if (this.issues.length > 0) {
        rankBelow = this.issues[0].lexoRank()
      }
    } else {
      // if not at bottom => just take the one below
      rankBelow = this.sprintIssues[event.newIndex + 1].lexoRank()
    }
    // get rank of previous issue, if present
    if (event.newIndex > 0) {
      rankAbove = this.sprintIssues[event.newIndex - 1].lexoRank()
    }

    this.sprintIssues[event.newIndex].rank = LexoRankService.calculateRank(rankAbove, rankBelow).toString()
    IssueService.updateRank([this.sprintIssues[event.newIndex]])
  }

  async startSprint() {
    const currentProduct = store.state.product.currentProduct
    if (!currentProduct) {
      throw new Error('No current product')
    }

    try {
      this.isLoading = true

      const payload = {
        product: currentProduct.iri(),
        issues: this.sprintIssues.map((issue: Issue) => issue.iri()),
        startAt: this.startEndDates[0],
        endAt: this.startEndDates[1],
        ...this.formData
      }

      const sprintResponse = await axios.post<AxiosResponse>('/sprints', payload)
      this.$router.push({ path: sprintResponse.headers.location })
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  displayDate(dates: string[]) {
    if (dates.length === 0) {
      return this.$i18n.t('sprint.fields.timeframe')
    }
    if (dates.length === 1) {
      return (this as any).$dateTime.formatDate(dates[0], this.$i18n.locale, 'll')
    }
    if (dates.length === 2) {
      let startDateStr = dates[0]
      let endDateStr = dates[1]
      if (dates[0] > dates[1]) {
        startDateStr = dates[1]
        endDateStr = dates[0]
      }

      const startDate = (this as any).$dateTime.formatDate(startDateStr, this.$i18n.locale, 'll')
      const endDate = (this as any).$dateTime.formatDate(endDateStr, this.$i18n.locale, 'll')
      return `${startDate} - ${endDate}`
    }
  }

  onlyWeekdays(date: string) {
    const dateObj = new Date(date)
    return !(dateObj.getDay() == 6 || dateObj.getDay() == 0)
  }
}
