



import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NoDataAlert extends Vue {
  @Prop() private text?: string

  get dialogText() {
    return this.text ? this.text : this.$i18n.t('general.list.noData')
  }
}
